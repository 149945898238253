import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../../../Atoms/Flex/Flex';
import data from '../../data';
import { useHistory } from "react-router-dom";
import Typography from '../../../../Atoms/Typography/Typography';
import styled from 'styled-components';
import {PRIMARY_COLOR,SECONDARY_COLOR} from "../../../../../EnvVariables/EnvVariables"
import { motion } from "framer-motion"

interface IProps{
    datas : any[];
    icons : any[];
    // history : any;
}

const AStyle = styled.a`
    &:hover{
        color:"#8c1111";
    }
`
const MyDiv = styled.div`
    &:hover{
        color:"#8c1111";
    }
`

const Link = ({datas, icons}:IProps):ReactElement =>{

    const [hoverIndex, setHoverIndex] = useState<number>(-1);
    return (
        <Flex   all= {{
                    gap:"15px"
                }}
        >
            {
                icons.map((El, i)=>

                    <motion.div
                        whileHover={{ 
                            scale: 1.1,
                            // backgroundColor:"rgba(0,0,0,.5)"
                        }}
                        onMouseEnter={()=>{
                            setHoverIndex(i)
                        }}
                        onMouseLeave={()=>{
                            setHoverIndex(-1)
                        }}
                        key={`link-${i}`}
                    >
                    <AStyle  style={{
                                cursor:"pointer",
                                margin:"0 12px",
                                textDecoration:"none",
                            }}
                        
                        href={datas[i].url}
                    >
                        <Flex   all={{
                                    alignItems:"center",
                                    gap:"8px"
                                }} 
                        >
                            

                            {
                                i !== 2 &&
                                <El size="1.2rem" color="black"/>    
                            }
                            
                            {
                                i === 2 &&
                                <El size="1.5rem" color="black"/> 
                                // <motion.div
                                //     animate={{
                                //         rotate:[10,20,10,0,-10,-20,-10,0],
                                //         x:[-1,0,1,0,-1],
                                //         y:[-1,0,1,0,-1],
                                //     }}
                                //     transition={{ ease: "linear", duration: .5, repeat: Infinity }}
                                // >
                                //     <El size="1.5rem" color="black"/> 
                                // </motion.div>
                                   
                            }
                        
                            <Typography variant='h4' color={hoverIndex === i ? SECONDARY_COLOR : PRIMARY_COLOR}>
                                {datas[i].name}
                            </Typography>
                        </Flex>
                        
                    </AStyle>    
                    </motion.div>
                )
            }
        </Flex>
    );
    
}

export default Link;
