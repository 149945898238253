import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';
import BgImgContainer from '../../Atoms/BgImgContainer/BgImgContainer';
import data from './data';
import Typography from '../../Atoms/Typography/Typography';
import TitleBlock from '../../Molecules/TitleBlock/TitleBlock';
import ImagePreview from '../../Molecules/ImagePreview/ImagePreview';
import Paper from "../../Atoms/Paper/Paper"
import { motion, AnimatePresence } from 'framer-motion';

interface IProps{}

const Photos = ({}:IProps):ReactElement =>{

    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        console.log("selectedInde:", selectedIndex);
        
    }, [selectedIndex])

    return (
        <Flex   all={{flexDirection:"column", gap:"20px"}}
                style={{
                    position: "relative",
                    // borderRadius:"5px",
                }}
        >
            <BgImgContainer backgroundImage={data.one.imgSrc} 
                            all={{height:"80vh"}}
                            mobile={{height:"48vh"}}
            >
                
                    <Flex   all={{
                                padding:"20px",
                                flexDirection:"column",
                                height:"100%",
                                width:"100%",
                                backgroundColor:"",
                                justifyContent:"center",
                                alignItems:"center",
                            }}
                    >
                        <motion.div
                            initial={{
                                transitionDuration:".5s",
                                scale: 0
                            }}
                            animate={{
                                scale:1
                            }}
                            style={{
                                display: "flex",
                                justifyContent:"center",
                            }}
                        >
                            <Typography variant='h1' color='white' textAlign="center">
                                {data.one.title}
                            </Typography>
                        </motion.div>
                        
                    </Flex>
                
            </BgImgContainer>

            <Flex   all={{
                            flexDirection:"column",
                            
                        }}
            >
                <motion.div
                            initial={{
                                transitionDuration:".5s",
                                transitionDelay:".5s",
                                x: "-110%",
                            }}
                            animate={{
                                x:0
                            }}
                            style={{
                                padding:"0px 8px"
                            }}
                >
                    {/* <Typography variant='h1' color='white'>
                        {data.one.title}
                    </Typography> */}
                    <TitleBlock text={data.two.title} color="black" />
                </motion.div>
                

                <Paper bgColor='#F3F3F3' padding='5px'>
                    <Flex   all={{
                                wrap:"wrap",
                                //margin:"0 100% 0 0"
                            }}
                            style={{
                                
                            }}
                    >
                        {
                            data.two.imgs.map((img, i)=>
                                <Flex   all={{
                                            width:"33%",
                                            backgroundColor:""
                                        }}
                                        laptop={{
                                            width:"49%",
                                        }}
                                        mobile={{
                                            width:"100%",
                                        }}
                                        style={{
                                            // transform:"rotate(120deg) translate(100vw, 0px)",
                                            
                                            transition:"0.5s"
                                            // right:"50vw",
                                            // position:"absolute"
                                        }}
                                        key={`two-img-${i}`}
                                >
                                    <Flex   all={{
                                                margin:"5px",
                                                width:"100%",
                                                backgroundColor:"",
                                                // padding:"20px"
                                            }}
                                            onClick={()=>{
                                                setSelectedIndex(i);
                                            }}
                                    >
                                        <ImagePreview   src={img.img}
                                                        name={img.name}
                                                        prev={()=>{}}
                                                        next={()=>{}}
                                        />
                                    </Flex>
                                    
                                </Flex>
                            )
                        }
                        
                    </Flex>
                </Paper>
                
                
            </Flex>
            
            
            
                <AnimatePresence>
                    {
                        selectedIndex>=0 &&
                        <Flex   style={{
                                    position: "fixed",
                                    width: "100vw",
                                    height: "100vh",
                                    zIndex:999999,
                                    backgroundColor:"rgba(0,0,0,.8)",
                                    top:"0",
                                    left:"0",

                                }}
                                all={{
                                    justifyContent:"center",
                                    alignItems:"center"
                                }}
                                onClick={()=>{setSelectedIndex(-1)}}
                        >

                            <motion.div
                                    initial={{ 
                                        opacity: 0,
                                        scale: 0,
                                    }}
                                    animate={{ 
                                        opacity: 1,
                                        scale: 1,
                                    }}
                                    exit={{ opacity: 0 }}
                                    style={{
                                        backgroundColor:"",
                                        // maxHeight:"70%",
                                    }}
                                >
                                <img src={data.two.imgs[selectedIndex].img} 
                                        style={{
                                            maxHeight:"80%",
                                            maxWidth:"80%",
                                        }}
                                />
                            </motion.div>
                        </Flex>
                    }
                </AnimatePresence>
            
            

        </Flex>
    );

}
export default Photos;