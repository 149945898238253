import { ReactElement,useState,useEffect } from 'react'
import { TTypoVariant } from '../../../Types/Typography/Typography';
import Flex from '../Flex/Flex';
import styled from 'styled-components';
import { ITypography } from '../../../Interfaces/Typography/Typography';



const getColor = (variant:TTypoVariant):string=>{
    switch (variant){
        case "body1" : return "#262c32";
        case "caption1" : return "#A1A1A1";
        case "caption2" : return "#A1A1A1";
        default : return "#262c32";
    }
}

const getFontSize = (variant:TTypoVariant):string=>{
    switch (variant){
        case "body1" : return "14px";
        case "body2" : return "16px";
        case "caption1" : return "16px";
        case "caption1" : return "12px";
        case "h1" : return "45px";
        case "h2" : return "30px";
        case "h3" : return "20px";
        case "h4" : return "17px";
        default : return "#12px";
    }
}

const getFontWeight = (variant:TTypoVariant):string=>{
    switch (variant){
        case "body1" : return "500";
        case "body2" : return "550";
        case "caption1" : return "500";
        case "caption2" : return "450";
        case "h1" : return "700";
        case "h2" : return "600";
        case "h3" : return "600";
        case "h4" : return "600";
        default : return "";
    }
}

const TypoStyle = styled.span<ITypography>`
    color:${(props)=> props.color ?? getColor(props.variant || "body1")};
    color:${(props)=> props.color ?? getColor(props.variant || "body1")};
    font-weight:${(props)=> props.fontWeight ?? getFontWeight(props.variant || "body1")};
    font-size:${(props)=> props.fontSize ?? getFontSize(props.variant || "body1")};
    margin:${(props)=> props.margin ?? ""};

`

const Typography = ({variant="body1",color, children, width, textAlign="left", margin}:ITypography):ReactElement=>{
    return (
        <Flex style={{
                    width: width ?? "",
                    // backgroundColor:"pink"
                }}
        >
            <TypoStyle  variant={variant} 
                        color={color} 
                        style={{textAlign:textAlign ?? ""}}
                        margin={margin}
            >
                {children}
                
            </TypoStyle>
        </Flex>
        
    );
}

export default Typography;
