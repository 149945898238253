import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';
import BgImgContainer from '../../Atoms/BgImgContainer/BgImgContainer';
import styled from 'styled-components';
import { Button } from 'antd';
import Typography from '../../Atoms/Typography/Typography';
import data from "./data";
import TitleBlock from '../../Molecules/TitleBlock/TitleBlock';
import DescriptionBlock from '../../Molecules/DescriptionBlock/DescriptionBlock';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../EnvVariables/EnvVariables';
import Paper from "../../Atoms/Paper/Paper"
import { useAnimation, motion, AnimationControls,useCycle } from 'framer-motion';


interface IProps{}

const PATH_COVER_IMG = "https://www.restaurantbuffet.fr/i/buffet/3/4/9/2/0/4/1/5/0/3/4/8/4/1637834774_432/d5a0fcb904a1419cab87ae7675f49ac2.website_hd.jpg"

const ImgContainer = styled(Flex)`
    background-image: url(${PATH_COVER_IMG});
`
const SpText = styled.p`
    color: #FFF;
    text-shadow: 2px 2px 0 ${PRIMARY_COLOR};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    padding: 0;
    letter-spacing: 2.5px;
    margin: 15px 0 25px 0;
`

const variant = {
    visible:{
        opacity:1, 
        scale:1, 
        transition:{
            duration:1,
        },
        rotate: 0,
    },
    hidden: { 
        opacity: 0, 
        scale: 0,
        rotate: -80 
    }
}

const Home = ({}:IProps):ReactElement =>{
    const locationTab = window.location.href.split("/");
    const location = locationTab[locationTab.length-1];
    const controls:AnimationControls = useAnimation();
    console.log("location:", location);
    

    return (
        <>
            {
                !location &&
                <Flex all={{flexDirection:"column", gap:"0px"}}>
                    
                    {/* block1 */}
                    <BgImgContainer backgroundImage={data.one.imgSrc} 
                                    all={{height:"80vh"}}
                                    mobile={{height:"48vh"}}
                    >
                        <Flex   all={{
                                    padding:"50px",
                                    flexDirection:"column",
                                    height:"100%",
                                    width:"100%",
                                    backgroundColor:"",
                                    justifyContent:"center",
                                    alignItems:"flex-start"
                                }}
                                laptop={{
                                    alignItems:"center",
                                }}
                                mobile={{
                                    alignItems:"center",
                                }}    
                        >
                            <motion.div
                                style={{
                                    textAlign:"center",
                                    padding:"0 0 0 18px"
                                }}
                                initial={{
                                    scale: 0,
                                    transitionDuration:".5s"
                                }}
                                animate={{
                                    scale: 1
                                }}
                            >
                                <Typography variant='h1' 
                                        color="white" 
                                        textAlign='center'>
                                    {data.one.title}
                                </Typography>
                            </motion.div>
                            
                            
                            <motion.div
                                initial={{
                                    x: "-110%",
                                    transitionDuration:".5s",
                                    transitionDelay:".5s"
                                }}
                                animate={{
                                    x: 0
                                }}
                            >
                                <SpText style={{
                                            width:"330px",
                                            textAlign:"center",
                                        }}
                                >
                                    {data.one.desc}
                                </SpText>
                            </motion.div>
                            

                            <motion.div initial={{
                                            scale:0,
                                            x:-100,
                                            transitionDelay:"1s"
                                        }}
                                        animate={{
                                            scale:1,
                                            x:0
                                        }}
                                        style={{
                                            transition: ".5s"
                                        }}
                                        whileHover={{
                                            scale:1.1
                                        }}
                                        // variants={variant}
                            >
                                <Paper bgColor='rgba(10,10,10,.5)'>
                                    <Flex all={{
                                                backgroundColor:``,
                                                flexDirection:"column"
                                            }}
                                    >
                                        {
                                            data.one.details.map(detail=>
                                                <Typography width='300px' color="white" textAlign='center'>
                                                    {detail}
                                                </Typography>
                                            )
                                        }

                                        <a href="#Contact">
                                        <Button style={{
                                                    backgroundColor:"rgba(0,0,0,0)",
                                                    color:"white",
                                                    zIndex:1,
                                                    margin:"20px 0 0 0"
                                                }}
                                            size='large'
                                        >
                                            {data.one.btnInfo}
                                        </Button>
                                        </a>
                                        
                                    </Flex>
                                    
                                </Paper>
                            
                            </motion.div>
                            
                            
                        </Flex>
                    </BgImgContainer>

                    {/* block 2-3 */}
                    <Flex   all={{flexDirection:"column"}}
                            desktop={{flexDirection:"row"}}
                    >
                        {/* block 2 */}
                        <Flex all={{
                                    flexDirection:"column",
                                    backgroundColor:`${PRIMARY_COLOR}`,
                                    flexGrow:1,
                                    minHeight:"400px",
                                    padding:"35px 75px",

                                }}
                                laptop={{
                                    padding:"35px 20px",
                                }}
                                mobile={{
                                    padding:"35px 25px",
                                    backgroundColor:""
                                }}
                        >
                            <TitleBlock text={data.two.title}/>
                            
                                <img src="./Images/menuBuffet1.png" alt="" width={"100%"} />
                                

                        </Flex>

                        {/* block 3 */}
                        <BgImgContainer backgroundImage={data.three.imgSrc} 
                                        all={{
                                            minHeight:"400px",
                                            minWidth:"50%",
                                            justifyContent:"center",
                                            alignItems:"center"
                                        }}
                        >
                            <Button style={{
                                        backgroundColor:`${PRIMARY_COLOR}`,
                                        color:"white",
                                        zIndex:1,
                                        margin:"20px 0 0 0",
                                    }}
                                    size='large'
                                    onClick={()=>{window.location.href="/Photos"}}
                            >
                                {data.three.btnInfo}
                            </Button>
                        </BgImgContainer>
                    </Flex>

                    {/* block 4-5 */}
                    <Flex   all={{
                                flexDirection:"column",
                                border:`2px solid ${PRIMARY_COLOR}`
                            }}
                            desktop={{flexDirection:"row"}}
                    >
                        <Flex all={{
                                    flexDirection:"column",
                                    backgroundColor:"",
                                    flexGrow:1,
                                    minHeight:"400px",
                                    padding:"35px 70px",
                                }}
                        >
                            <TitleBlock text={data.four.title} color={`${PRIMARY_COLOR}`}
                            />

                            <Flex all={{
                                        flexDirection:"column",
                                        gap:"30px",
                                    }}
                            >
                                {
                                    data.four.info?.map((el, i)=>
                                        <DescriptionBlock   title={{
                                                                children:el?.name,
                                                                color:PRIMARY_COLOR,
                                                            }} 
                                                            desc={{texts:el?.desc}}
                                                            key={`DescriptionBlock-four-${i}`}
                                        />
                                    )
                                }
                                
                            </Flex>
                        </Flex>

                        <Flex   all={{
                                    minWidth:"50%",
                                    flexGrow:1,
                                    backgroundColor:`${PRIMARY_COLOR}`,
                                    minHeight:"400px",
                                    padding:"35px 70px",
                                    // justifyContent:"center",
                                    flexDirection:"column",
                                }}
                        >
                            <TitleBlock text={data.five.title} 
                            />
                            <Flex all={{
                                        flexDirection:"column",
                                        gap:"30px",
                                    }}
                            >
                                {
                                    data.five.info?.map((el,i )=>
                                        <DescriptionBlock   title={{
                                                                children:el?.name,
                                                                color:"white",
                                                            }} 
                                                            desc={{texts:el?.desc}}
                                                            key={`DescriptionBlock-five-${i}`}
                                        />
                                    )
                                }
                                
                            </Flex>
                        </Flex>
                    </Flex>
                    
                </Flex>


            }
        </>
        
        
    );

}
export default Home;
