import { ReactElement,useState,useEffect } from 'react'
import Flex,{ ITFlex, IFlex } from '../Flex/Flex';
import styled from 'styled-components';

interface IProps{
    laptop ?: ITFlex;
    mobile ?: ITFlex;
    desktop ?: ITFlex;
    all ?: ITFlex;
    children
}

interface IBgImgContainer extends IFlex{
    backgroundImage : string;
}

const BgImgContainer = styled(Flex)<IBgImgContainer>`
    ${({ backgroundImage }) => backgroundImage && `background-image : url(${backgroundImage});`}
    background-size: cover;
    background-position: center;
    
`

export default BgImgContainer;

