import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../../Atoms/Flex/Flex';
import { AiOutlineMenu } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';

interface IProps{
    isLinkVisible:boolean,
    setIsLinkVisible: any,
}

const Mobile = ({isLinkVisible, setIsLinkVisible}:IProps):ReactElement =>{
    return (
        <>
            <Flex   all={{alignItems:"center", cursor:"pointer"}}
                        desktop={{hide:true}}
                        style={{
                            position:"relative"
                        }}
            >
                <div style={{
                            transition:".8s", display: isLinkVisible ? "none" : "flex",
                            position:"absolute",
                            right:"20px",
                            zIndex:isLinkVisible ? 0 : 1,
                        }}
                >
                    <AiOutlineMenu color='black' size="1.5rem"
                                    onClick={()=>{setIsLinkVisible(true)}}
                    />
                </div>
                <div style={{
                            transition:".8s", display: isLinkVisible ? "flex" : "none",
                            position:"absolute",
                            right:"20px",
                            zIndex:isLinkVisible ? 1 : 0,
                        }}
                >
                    <GrClose    color='black' size="1.5rem"
                                onClick={()=>{setIsLinkVisible(false)}}
                    />
                </div>
                        
            </Flex>
        </>
        
    );
}

export default Mobile;
