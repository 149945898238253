import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';
import Footer from '../../Organisms/Footer/Footer';
import Header from '../../Organisms/Header/Header';
import { HiOutlineArrowUp } from 'react-icons/hi'

interface IProps{
    children:any;
}

const Global1 = ({children}:IProps):ReactElement =>{

    const [headerHidden, setHeaderHidden] = useState(false);
    const [arrowHover, setArrowHover] = useState(false);


    const handleScroll = (e)=>{

    }
    useEffect(()=>{
        // window.addEventListener("scroll",handleScroll);
    },[])

    return (
        <Flex   all={{
                    flexDirection:"column"
                }}
                style={{
                    position:"relative",
                    transition:".5s",
                }}
        >
            <Flex   style={{
                        position:"absolute",
                        top:"0px",
                        width:"100%",
                        transform: headerHidden ? "translate(0px, -80px)" : "",
                    }}
            >
                {/* <Header backgroundColor={`rgba(92,62,43,.9)`}/> */}
                <Header backgroundColor={`white`}/>
            </Flex>
            
            <Flex   style={{
                        position:"fixed",
                        bottom:"20px",
                        right:"20px",
                        backgroundColor:arrowHover ? "rgba(0,0,0,.5)" : "rgba(0,0,0,.3)",
                        padding:"8px",
                        borderRadius:"20px",
                        cursor:"pointer",
                        transition:".5s"
                    }}
                    onMouseEnter={()=>{
                        setArrowHover(true)
                    }}
                    onMouseLeave={()=>{
                        setArrowHover(false)
                    }}
                    onClick={()=>{
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }}

            >
                <HiOutlineArrowUp   size="1.5rem"
                />
            </Flex>

            <Flex all={{margin:"80px 0px 0px 0px", flexDirection:"column"}}>
                {children}
                <div style={{}}>
                    <Footer/>
                </div>
                
            </Flex>
            

            
        </Flex>
    );

}
export default Global1;
