import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../../../Atoms/Flex/Flex';
import Typography from '../../../../Atoms/Typography/Typography';
// import {PRIMARY_COLOR,SECONDARY_COLOR} from "../../../../../EnvVariables/EnvVariables"
import { motion } from "framer-motion"
import {BsPinMap} from "react-icons/bs";
import {BsFillPhoneVibrateFill} from "react-icons/bs"
import { useHistory } from 'react-router-dom';

interface IProps{
    datas : any[];
    setVisible : any;
    icons : any[];
}


const contact = {
    address : {
        name:"ADRESSE",
        value : "425 Avenue Bollée, 72000 Le Mans"
    },
    tel: {
        name:"TEL",
        value : "02 43 85 90 31"
    }
};

const LinkDisplay = ({datas, setVisible, icons}:IProps):ReactElement =>{
    let history = useHistory();
    return (
        <Flex all={{flexDirection:"column", width:"100%"}}>
            <Flex   all={{
                            gap:"15px",
                            margin:"0px 0px 15px 0",
                            justifyContent:"center",
                            alignItems:"center",
                            backgroundColor:"",
                            flexDirection:"column"
                        }}
                        
            >
                <a href='https://www.google.com/maps/dir/48.8655909,2.378983/425+Av.+Boll%C3%A9e,+72000+Le+Mans/@48.4271362,0.1312897,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47e28eb4e304d24f:0xce75bf9b87dc1c87!2m2!1d0.2311432!2d47.999016'>
                    <Flex   all={{alignItems:"center"}}
                            style={{
                                cursor:"pointer"
                            }}
                    >
                        <BsPinMap color="white"/>
                        <Typography variant='caption2' margin="0 0px 0 3px">
                            {contact.address.value}
                        </Typography>
                    </Flex>
                </a>
                
                    

                <a href={'tel:'+contact.tel.value}>
                    <Flex   all={{alignItems:"center"}}
                            style={{
                                cursor:"pointer",
                            }}
                    >
                        <motion.div
                            animate={{
                                rotate:[10,20,10,0,-10,-20,-10,0],
                                x:[-1,0,1,0,-1],
                                y:[-1,0,1,0,-1],
                            }}
                            transition={{ ease: "linear", duration: .5, repeat: Infinity }}
                            style={{
                                margin:"3px 0 0 0"
                            }}
                        >
                            <BsFillPhoneVibrateFill size="1.75rem" color="white"/> 
                        </motion.div>
                        <Typography variant='caption2' margin="0 0px 0 3px">
                            {contact.tel.value}
                        </Typography>
                    </Flex>
                </a>
                
                    
            </Flex>

            {
                icons.map((El, i)=>
                    <a  href={datas[i].url} 
                        key={`linkDisplay-${i}`}
                        style={{
                            backgroundColor:"",
                            border: "",
                            width: "100%",
                            margin:"0 0 20px 0",
                        }}
                        onClick={()=>{setVisible(false);}}
                    >
                        <Flex all={{
                                justifyContent:"center",
                                gap:"5px"
                            }}
                        >
                            {
                                i !== 2 &&
                                <El size="1.2rem" color="white"/>    
                            }
                            
                            {
                                i === 2 &&
                                <El size="1.5rem" color="white"/> 

                            }

                            <Typography color='white' variant='h4'>
                                {datas[i].name}
                            </Typography>
                        </Flex>
                    </a>
                )
            }
        </Flex>
    );
}

export default LinkDisplay;
