const data = {

    
    one:{
        title:"Accès/Contact",
        addr:"425 Avenue Bollée, 72000 Le Mans",
        tel:"02 43 85 90 31",
    },
    two:{
        title:"Nous contacter",
        btnInfo:"RÉSERVER",
    }
}

export default data;
