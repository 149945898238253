import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';
import BgImgContainer from '../../Atoms/BgImgContainer/BgImgContainer';
import styled from 'styled-components';
import { useAnimation, motion, AnimationControls } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Typography from '../../Atoms/Typography/Typography';

interface IProps{
    src : string;
    name : string;
    prev : any;
    next : any;
}

const ImgContainer = styled.img`
    :hover{
        background: rgba(0, 0, 0, .75);
        transform:scale(1.2);
        cursor:pointer;
    }
    overflow: hidden;
    border-radius: 6px;
`;

const variant = {
    visible:{
        opacity:1, 
        scale:1, 
        transition:{
            duration:1,
        },
        rotate: 0,
        borderRadius:"6px",
        
    },
    hidden: { 
        opacity: 0, 
        scale: 0,
        rotate: -80,
        borderRadius:"5px",
    }
}

const ImagePreview = ({src, name, prev, next}:IProps):ReactElement =>{

    
    const [isDark, setIsDark] = useState(false);
    const controls:AnimationControls = useAnimation();
    const [ref, inView] = useInView();

    // const handleEnter = (e:any)=>{
    //     console.log("enter");
        
    //     setIsDark(true);
    // }

    // const handleLeave = (e:any)=>{
    //     console.log("leave");
    //     setIsDark(false);
    // }

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
        else{
            controls.start("hidden");
        }
      }, [controls, inView]);

    return (
        // <Flex all={{
        //     width:"100%",
        //     backgroundColor:"",
        // }}
        // >
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={variant}
            // className="square"
            style={{
                width:"100%",
            }}
        >
            
                <Flex   all={{
                            width:"100%",
                            flexDirection:"column-reverse",
                            height:"250px",
                            // minHeight:"100%"
                        }}
                        style={{
                            transition:".4s",
                            overflow:"hidden",
                            position:"relative",
                            borderRadius:"6px",
                            // box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
                            boxShadow: "rgb(38, 57, 77) 0px 10px 15px -5px",
                        }}
                >
                    <Flex   style={{
                                position:"absolute",
                                borderRadius:"6px",
                            }}
                    >
                        <ImgContainer   src={src} 
                                        width="100%"
                                        height="100%"
                                        //height="auto"
                                        style={{
                                            transition:".6s",
                                            // backgroundColor: isDark ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.5)",
                                            borderRadius:"6px",
                                            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                                        }}
                                        
                        />
                    </Flex>
                    
                    <Flex   all={{
                                justifyContent:"center",
                                backgroundColor:"black",
                                padding:"6px"
                            }}
                            style={{
                                zIndex:99,
                                opacity:.6
                            }}
                    >
                        <Typography color='white'>
                            {name}
                        </Typography>
                    </Flex>
                </Flex>
            
            
        </motion.div>
        
        // </Flex>
    );
}

export default ImagePreview;
