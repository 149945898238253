import styled from "styled-components";

export interface IPaperProps {
    zIndex ?: number;                       //*
    bgColor ?: string;                      //*
    margin ?: string;                       //*
    padding ?: string;                      //*
    boxShadow ?: string;                    //*
    borderRadius ?: string;                 //*
    borderWidth ?: string;
    border ?: string;                       //*
    boxShadowAfter ?: string;               //*
    reactTime ?: string;                    //*
    scalePropotion ?:number;                //*
    translateVector ?: {x?:string,y?:string}; //*
    rotateDeg ?: string;                    //*           
    width ?: string;                        //*
    height ?: string;                       //*
    minHeight ?: string;                    //*
    display?: string;                       //*
    cursor?: string;
    disableHover ?: boolean;
    opacity ?: string;
    position ?: string;
    minWidth ?: string;
    maxWidth ?: string;
}

const CardStyle  = styled.div<IPaperProps> `
    ${({ display }) => display && `display : ${display};`}
    
    
    background-color:${(props)=> props.bgColor ?? '#FFFFFF'};
    border-radius:${(props)=> props.borderRadius ?? '8px'};
    ${({ borderWidth }) => borderWidth && `border-width : ${borderWidth};`}
    padding:${(props)=> props.padding ?? '15px'};
    border:${(props)=> props.border ?? '0px'};
    transition:${(props) => props.reactTime ?? '.55s'};
    //transform: scaleY(1);
    /* transition-duration: 4s;
    transition-delay: 2s; */


    ${({zIndex})=>zIndex && `z-index: ${zIndex};`}
    ${({margin})=>margin && `margin: ${margin};`};
    ${({borderRadius})=>borderRadius && `border-radius: ${borderRadius};`};
    ${({opacity})=>opacity && `opacity: ${opacity};`};
   
    ${({ width }) => width && `width : ${width};`}
    ${({height})=>height && `height: ${height};`}
    ${({minHeight})=>minHeight && `min-height: ${minHeight};`};
    ${({minWidth})=>minWidth && `min-width: ${minWidth};`};
    ${({maxWidth})=>maxWidth && `max-width: ${maxWidth};`};
    ${({ boxShadow }) => boxShadow ? `box-shadow: ${boxShadow}` : 'box-shadow:0px 2px 2px rgba(0, 0, 0, 0.05)' };
    ${({ position }) => position && `position : ${position};`}

    //animation-duration: 750ms;
    //transform: rotate(0deg) scale(1) translate(0,0);
    

    ${(props) => (!props.disableHover) && `
        :hover{ 
            box-shadow:${props.boxShadowAfter ?? '0px 2px 15px rgba(39, 116, 233, 0.2)'};
            transform: 
                ${props.scalePropotion && `scale(${props.scalePropotion}) `}
                ${props.translateVector && `translate(${props.translateVector.x},${props.translateVector.y ?? '0px'}) `}
                ${props.rotateDeg && `rotate(${props.rotateDeg}) `};
            transform:scale(${props.scalePropotion});
        }
    `};
    
    

    ${({ cursor }) => cursor && `
        cursor : ${cursor};
    `};
    
`;

export default CardStyle;
