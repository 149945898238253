import styled from 'styled-components';
export type TFlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type TPosition = 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky' | 'inherit' | 'initial' | 'unset';
export type TJustifyContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
export type TAlignItems = 'strech' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
export type TAlignContent = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'space-between' | 'space-around';
export type TWrap = 'nowrap' | 'wrap' | 'wrap-reverse';
export type TCursor = 'pointer' | 'help' | 'wait' | 'crosshair' | 'not-allowed' | 'zoom-in' | 'grab';
export type TScroll = 'scroll' | 'hidden' | 'auto' | 'visible' | '' | undefined;
export interface ITFlex {
    width ?: string | undefined;
    maxWidth ?: string | undefined;
    maxHeight ?: string | undefined;
    minWidth ?: string | undefined

    height ?: string | undefined;
    minHeight ?: string | undefined;
    padding ?: string | undefined;
    margin ?: string | undefined;

    flexDirection ?: TFlexDirection | undefined;
    justifyContent ?: TJustifyContent | undefined;
    alignItems ?: TAlignItems | undefined;
    alignContent ?: TAlignContent | undefined;
    wrap ?: TWrap | undefined;

    scroll ?: TScroll;
    overflowX ?: TScroll;
    overflowY ?: TScroll;

    backgroundColor ?: string;
    borderRadius ?: string | undefined;
    border ?: string | undefined;
    display ?: string;
    flexGrow ?:string | number;

    cursor ?: string;
    boxShadow ?: string;

    gap ?: string;

    hide ?: boolean;
}

export interface IFlex {
    laptop ?: ITFlex;
    mobile ?: ITFlex;
    desktop ?: ITFlex;
    all ?: ITFlex;
    cursor ?: TCursor 
    position ?: string;
}

const mobileMaxWidth = 480;
const laptopMinWidth = 481;
const laptopMaxWidth = 1024;
const desktopMinWidth = 1025;
const desktopMaxWidth = 8300;

const Flex = styled.div<IFlex> `
    display:flex;
    ${({ cursor }) => cursor && `cursor : ${cursor};`}
    ${({ position }) => position && `position : ${position};`}

    ${({ all }) => all?.height && `height : ${all.height};`}
    ${({ all }) => all?.width && `width : ${all.width};`}
    ${({ all }) => all?.maxHeight && `max-height : ${all.maxHeight};`}
    ${({ all }) => all?.maxWidth && `max-width : ${all.maxWidth};`}
    ${({ all }) => all?.minWidth && `min-width : ${all.minWidth};`}

    ${({ all }) => all?.padding && `padding : ${all.padding};`}
    ${({ all }) => all?.margin && `margin : ${all.margin};`}
    ${({ all }) => all?.flexDirection && `flex-direction : ${all.flexDirection};`}
    ${({ all }) => all?.justifyContent && `justify-content : ${all.justifyContent};`}
    ${({ all }) => all?.alignItems && `align-items : ${all.alignItems};`}
    ${({ all }) => all?.alignContent && `align-content : ${all.alignContent};`}
    ${({ all }) => all?.wrap && `flex-wrap : ${all.wrap};`}
    ${({ all }) => all?.backgroundColor && `background-color : ${all.backgroundColor};`}
    
    ${({ all }) => all?.scroll && `overflow-y : ${all.scroll};`}
    ${({ all }) => all?.overflowX && `overflow-x : ${all.overflowX};`}
    ${({ all }) => all?.overflowY && `overflow-y : ${all.overflowY};`}
   

    ${({ all }) => all?.display && `display : ${all.display};top: 0;`}
    ${({ all }) => all?.border && `border : ${all.border};`}
    ${({ all }) => all?.borderRadius && `border-radius : ${all.borderRadius};`}
    ${({ all }) => all?.flexGrow && `flex-grow : ${all.flexGrow};`}
    ${({ all }) => all?.cursor && `cursor : ${all.cursor};`}
    ${({ all }) => all?.minHeight && `min-height : ${all.minHeight};`}
    ${({ all }) => all?.boxShadow && `box-shadow : ${all.boxShadow};`}
    ${({ all }) => all?.gap && `gap : ${all.gap};`}
    ${({ all }) => all?.hide && `display : ${all.hide ? 'none' : 'flex' };`}



    @media (max-width: ${mobileMaxWidth}px) { 
        ${({ mobile }) => mobile?.height && `height : ${mobile.height} !important;`}
        ${({ mobile }) => mobile?.width && `width : ${mobile.width} !important;`}
        ${({ mobile }) => mobile?.maxWidth && `max-width : ${mobile.maxWidth};`}
        ${({ mobile }) => mobile?.maxHeight && `max-height : ${mobile.maxHeight};`}
        ${({ mobile }) => mobile?.minWidth && `min-width : ${mobile.minWidth};`}

        ${({ mobile }) => mobile?.padding && `padding : ${mobile.padding} !important;`}
        ${({ mobile }) => mobile?.margin && `margin : ${mobile.margin} !important;`}
        ${({ mobile }) => mobile?.flexDirection && `flex-direction : ${mobile.flexDirection};`}
        ${({ mobile }) => mobile?.justifyContent && `justify-content : ${mobile.justifyContent};`}
        ${({ mobile }) => mobile?.alignItems && `align-items : ${mobile.alignItems};`}
        ${({ mobile }) => mobile?.alignContent && `align-content : ${mobile.alignContent};`}
        ${({ mobile }) => mobile?.wrap && `flex-wrap : ${mobile.wrap};`}
        ${({ mobile }) => mobile?.backgroundColor && `background-color : ${mobile.backgroundColor};`}
        
        
        ${({ mobile }) => mobile?.scroll && `overflow-y : ${mobile.scroll};`}
        ${({ mobile }) => mobile?.overflowX && `overflow-x : ${mobile.overflowX};`}
        ${({ mobile }) => mobile?.overflowY && `overflow-y : ${mobile.overflowY};`}

        ${({ mobile }) => mobile?.display && `display : ${mobile.display};top: 0;`}
        ${({ mobile }) => mobile?.border && `border : ${mobile.border};`}
        ${({ mobile }) => mobile?.borderRadius && `borderRadius : ${mobile.borderRadius};`}
        ${({ mobile }) => mobile?.flexGrow && `flex-grow : ${mobile.flexGrow};`}
        ${({ mobile }) => mobile?.cursor && `cursor : ${mobile.cursor};`}
        ${({ mobile }) => mobile?.minHeight && `min-height : ${mobile.minHeight};`}
        ${({ mobile }) => mobile?.boxShadow && `box-shadow : ${mobile.boxShadow};`}
        ${({ mobile }) => mobile?.gap && `gap : ${mobile.gap};`}
        ${({ mobile }) => mobile?.hide && `display : ${mobile.hide ? 'none' : 'flex' };`}
    };
    @media (min-width: ${laptopMinWidth}px) and (max-width: ${laptopMaxWidth}px){
        ${({ laptop }) => laptop?.height && `height : ${laptop.height};`}
        ${({ laptop }) => laptop?.width && `width : ${laptop.width};`}
        ${({ laptop }) => laptop?.maxHeight && `max-height : ${laptop.maxHeight};`}
        ${({ laptop }) => laptop?.maxWidth && `max-width : ${laptop.maxWidth};`}
        ${({ laptop }) => laptop?.minWidth && `min-width : ${laptop.minWidth};`}
        ${({ laptop }) => laptop?.padding && `padding : ${laptop.padding} !important;`}
        ${({ laptop }) => laptop?.margin && `margin : ${laptop.margin} !important;`}
        ${({ laptop }) => laptop?.flexDirection && `flex-direction : ${laptop.flexDirection};`}
        ${({ laptop }) => laptop?.justifyContent && `justify-content : ${laptop.justifyContent};`}
        ${({ laptop }) => laptop?.alignItems && `align-items : ${laptop.alignItems};`}
        ${({ laptop }) => laptop?.alignContent && `align-content : ${laptop.alignContent};`}
        ${({ laptop }) => laptop?.wrap && `flex-wrap : ${laptop.wrap};`}
        ${({ laptop }) => laptop?.backgroundColor && `background-color : ${laptop.backgroundColor};`}

        ${({ laptop }) => laptop?.scroll && `overflow-y : ${laptop.scroll};`}
        ${({ laptop }) => laptop?.overflowX && `overflow-x : ${laptop.overflowX};`}
        ${({ laptop }) => laptop?.overflowY && `overflow-y : ${laptop.overflowY};`}
        
        ${({ laptop }) => laptop?.display && `display : ${laptop.display};top: 0;`}
        ${({ laptop }) => laptop?.border && `border : ${laptop.border};`}
        ${({ laptop }) => laptop?.borderRadius && `borderRadius : ${laptop.borderRadius};`}
        ${({ laptop }) => laptop?.flexGrow && `flex-grow : ${laptop.flexGrow};`}
        ${({ laptop }) => laptop?.cursor && `cursor : ${laptop.cursor};`}
        ${({ laptop }) => laptop?.minHeight && `min-height : ${laptop.minHeight};`}
        ${({ laptop }) => laptop?.boxShadow && `box-shadow : ${laptop.boxShadow};`}
        ${({ laptop }) => laptop?.gap && `gap : ${laptop.gap};`}
        ${({ laptop }) => laptop?.hide && `display : ${laptop.hide ? 'none' : 'flex' };`}

    };
    @media (min-width: ${desktopMinWidth}px) and (max-width: ${desktopMaxWidth}px){
        ${({ desktop }) => desktop?.height && `height : ${desktop.height};`}
        ${({ desktop }) => desktop?.width && `width : ${desktop.width};`}
        ${({ desktop }) => desktop?.maxWidth && `max-width : ${desktop.maxWidth};`}
        ${({ desktop }) => desktop?.maxHeight && `max-height : ${desktop.maxHeight};`}
        ${({ desktop }) => desktop?.minWidth && `min-width : ${desktop.minWidth};`}
        ${({ desktop }) => desktop?.padding && `padding : ${desktop.padding} !important;`}
        ${({ desktop }) => desktop?.margin && `margin : ${desktop.margin} !important;`}
        ${({ desktop }) => desktop?.flexDirection && `flex-direction : ${desktop.flexDirection};`}
        ${({ desktop }) => desktop?.justifyContent && `justify-content : ${desktop.justifyContent};`}
        ${({ desktop }) => desktop?.alignItems && `align-items : ${desktop.alignItems};`}
        ${({ desktop }) => desktop?.alignContent && `align-content : ${desktop.alignContent};`}
        ${({ desktop }) => desktop?.wrap && `flex-wrap : ${desktop.wrap};`}
        ${({ desktop }) => desktop?.backgroundColor && `background-color : ${desktop.backgroundColor};`}
        
        
        ${({ desktop }) => desktop?.scroll && `overflow-y : ${desktop.scroll};`}
        ${({ desktop }) => desktop?.overflowX && `overflow-x : ${desktop.overflowX};`}
        ${({ desktop }) => desktop?.overflowY && `overflow-y : ${desktop.overflowY};`}
        
        ${({ desktop }) => desktop?.display && `display : ${desktop.display};top: 0;`}
        ${({ desktop }) => desktop?.border && `border : ${desktop.border};`}
        ${({ desktop }) => desktop?.borderRadius && `borderRadius : ${desktop.borderRadius};`}
        ${({ desktop }) => desktop?.flexGrow && `flex-grow : ${desktop.flexGrow};`}
        ${({ desktop }) => desktop?.cursor && `cursor : ${desktop.cursor};`}
        ${({ desktop }) => desktop?.minHeight && `min-height : ${desktop.minHeight};`}
        ${({ desktop }) => desktop?.boxShadow && `box-shadow : ${desktop.boxShadow};`}
        ${({ desktop }) => desktop?.gap && `gap : ${desktop.gap};`}
        ${({ desktop }) => desktop?.hide && `display : ${desktop.hide ? 'none' : 'flex' };`}
    };
    // copmpatibility between navigator ...
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    };
    
    ::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 10px;
    };

    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 5px;
        background: black; 
    };
`;

export default Flex;