import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../../Atoms/Flex/Flex';
import Link from '../Components/Link/Link';
import Typography from '../../../Atoms/Typography/Typography';
import {AiOutlinePhone} from "react-icons/ai";
import {BsPinMap} from "react-icons/bs";
import { motion } from "framer-motion";
import {BsFillPhoneVibrateFill} from "react-icons/bs"


interface IProps{
    datas: any[],
    icons:any[],
}

const contact = {
    address : {
        name:"ADRESSE",
        value : "425 Avenue Bollée, 72000 Le Mans"
    },
    tel: {
        name:"TEL",
        value : "02 43 85 90 31"
    }
};

const Desktop = ({datas, icons}:IProps):ReactElement =>{
    return (
        <Flex   all={{
                    backgroundColor:"",
                    alignItems:"center",
                    padding:"16px 20px 0 0"
                }}
                mobile={{
                    hide:true
                }}
                laptop={{
                    hide:true
                }}
        >
            <Flex   all={{
                            gap:"20px",
                            margin:"0px 20px 0 0",
                            justifyContent:"center",
                            alignItems:"center",
                            backgroundColor:"",
                        }}
                        mobile={{
                            margin:"12px 50px 0 0"
                        }}
            >
                <a href='https://www.google.com/maps/dir/48.8655909,2.378983/425+Av.+Boll%C3%A9e,+72000+Le+Mans/@48.4271362,0.1312897,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47e28eb4e304d24f:0xce75bf9b87dc1c87!2m2!1d0.2311432!2d47.999016'>
                    <Flex   all={{alignItems:"center"}}
                            style={{
                                cursor:"pointer"
                            }}
                    >
                        <BsPinMap color="black"/>
                        <Typography variant='caption2' margin="0 0px 0 3px">
                            {contact.address.value}
                        </Typography>
                    </Flex>
                </a>
                
                    

                <a href={'tel:'+contact.tel.value}>
                    <Flex   all={{alignItems:"center"}}
                            style={{
                                cursor:"pointer",
                            }}
                    >
                        <motion.div
                            animate={{
                                rotate:[10,20,10,0,-10,-20,-10,0],
                                x:[-1,0,1,0,-1],
                                y:[-1,0,1,0,-1],
                            }}
                            transition={{ ease: "linear", duration: .5, repeat: Infinity }}
                            style={{
                                margin:"3px 0 0 0"
                            }}
                        >
                            <BsFillPhoneVibrateFill size="1.75rem" color="black"/> 
                        </motion.div>
                        <Typography variant='caption2' margin="0 0px 0 3px">
                            {contact.tel.value}
                        </Typography>
                    </Flex>
                </a>
                
                    
            </Flex>

            <Link datas={datas} icons={icons}
            />
        </Flex>
    );
}

export default Desktop;
