import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';
import data from './data';
import { useHistory, useLocation } from "react-router-dom";
import Desktop from './Desktop/Desktop';
import Mobile from './Mobile/Mobile';
import LinkDisplay from './Components/LinkDisplay/LinkDisplay';
// import { Button } from 'antd';
import styled from 'styled-components';
import {BsFillPhoneVibrateFill} from "react-icons/bs"
import {FaAllergies} from "react-icons/fa"
import {GrGallery} from "react-icons/gr"
import {RiGalleryFill} from "react-icons/ri";
import {MdContactPhone} from "react-icons/md"
import {AiOutlinePhone} from "react-icons/ai"
import {BsPinMap} from "react-icons/bs"
import Typography from '../../Atoms/Typography/Typography';


interface IProps{
    backgroundColor ?: string;

}

const Container = styled(Flex)`
    z-index:999;
    position:fixed;
    box-shadow:0px 2px 3px rgba(0, 0, 0, 0.05);
    transition: top 0.3s;
`

const Icons = [
    RiGalleryFill, FaAllergies, MdContactPhone
];

const contact = {
    address : {
        name:"ADRESSE",
        value : "425 Avenue Bollée, 72000 Le Mans"
    },
    tel: {
        name:"TEL",
        value : "02 43 85 90 31"
    }
};

const Header = ({backgroundColor="white"}:IProps):ReactElement =>{
    const history = useHistory();
    const [isLinkVisible, setIsLinkVisible] = useState(false);
    

    useEffect(()=>{
        console.log("location:", window.location.href);
        
    },[])

    return (
        <>
            <Container   all={{
                            width:"100%",
                            padding:"0px 0px 0px  10px",
                            justifyContent:"space-between",
                            backgroundColor,
                            
                        }}
                        style={{
                            transition:".4s"
                        }}
                        id="header"
            >
            
                <a href='/'>
                    <img    src="./Images/logo3.png" width="160px" 
                            style={{
                                margin:"12px",
                            }}
                    />
                </a>
                
                
                

                {/* desktop display */}
                <Desktop datas={data} icons={Icons} />

                {/* mobile and laptop display */}
                <Mobile isLinkVisible={isLinkVisible} setIsLinkVisible={setIsLinkVisible}/>
            </Container>

            <Flex   style={{
                            top:"-100%",
                            left:"0px",
                            width:"100%", 
                            height:"100%",
                            position:"fixed",
                            transition:".5s",
                            transform: isLinkVisible ? "translate(0px,100%)" : "",
                            zIndex:99
                    }}
                    all={{
                        flexDirection:"column",
                        backgroundColor:"rgba(0,0,0,0.9)"
                    }}
            >
                <Flex   all={{
                            margin:"150px 0 0 0",
                            width:"100%",
                            justifyContent:"center",
                        }}
                >
                    <LinkDisplay datas={data} setVisible={setIsLinkVisible} icons={Icons}/>
                </Flex>
            </Flex>
        </>
        
    );

}
export default Header;
