import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';
import Typography from '../../Atoms/Typography/Typography';
import {TTypoVariant} from "../../../Types/Typography/Typography"

interface IProps{
    variant ?: TTypoVariant;
    text : string;
    color ?: string;
}

const TitleBlock = ({variant="h2", text, color="white"}:IProps):ReactElement =>{
    return (
        <Flex all={{
                    flexDirection:"column",
                    alignItems:"center",
                }}
        >
            <Typography variant={variant} color={color}>
                {text}
            </Typography>
            
            <div style={{
                        backgroundColor:color,
                        height:"2px",
                        width : "45px",
                        margin:"20px 0 20px 0"
                    }} 
            />
        </Flex>
    );
}

export default TitleBlock;
