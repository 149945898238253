import { ReactElement,useState,useEffect } from 'react'
// import { BrowserRouter as Router, Switch} from 'react-router-dom'
// import { Route, Routes } from "react-router";
// import { Route, Switch } from "react-router";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './Components/Pages/Home/Home';
import Menu from './Components/Pages/Menu/Menu';
import Contact from './Components/Pages/Contact/Contact';
import Photos from './Components/Pages/Photos/Photos';
import Allergenes from './Components/Pages/Allergenes/Allergenes';

interface IProps{}

const RouteElement = ({}:IProps):ReactElement =>{
    return (

        <Router>
            <Route path="/">
                <Home/>
            </Route>
            {/* <Route path="/Menu">
                <Menu/>
            </Route> */}
            <Route path="/Contact">
                <Contact/>
            </Route>
            <Route path="/Photos">
                <Photos/>
            </Route>

            <Route path="/Allergenes">
                <Allergenes/>
            </Route>
        </Router>
    );
}

export default RouteElement;
