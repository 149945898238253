import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';
import Typography from '../../Atoms/Typography/Typography';
import {ITypography} from "../../../Interfaces/Typography/Typography"

interface IDesc{
    typo?:ITypography,
    texts:string[],
}

interface IProps{
    title ?: ITypography;
    desc ?: IDesc;
}


const DescriptionBlock = ({title, desc}:IProps):ReactElement =>{
    return (
        <Flex all={{
                    flexDirection:"column",
                    alignItems:"center",
                }}
        >
            <Typography variant={title?.variant ?? "h3"} 
                        color={title?.color ?? "white"}
                        textAlign={title?.textAlign ?? "center"}
                        width={title?.width}
                        fontWeight={title?.fontWeight}
                        fontSize={title?.fontSize}
            >
                {title?.children}
            </Typography>
            

            {
                desc?.texts?.map((el, i)=>
                    <Flex   all={{backgroundColor:"", margin:"0px 0 0 0"}}
                            key={`DescriptionBlock-${i}`}
                    >
                        <Typography variant={desc?.typo?.variant ?? "caption1"} 
                                    color={desc?.typo?.color}
                                    textAlign={desc?.typo?.textAlign ?? "center"}
                                    width={desc?.typo?.width}
                                    fontWeight={desc?.typo?.fontWeight}
                                    fontSize={desc?.typo?.fontSize}
                        >
                            {el}
                        </Typography>
                    </Flex>
                    
                )
            }
            
        </Flex>
    );
}

export default DescriptionBlock;
