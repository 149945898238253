const data = {
    title:"Allergènes alimentaires",
    titleDescs:[
        "Les allergies alimentaires peuvent être responsables de troubles de santé graves.",
        "Pour diminuer le risque, adoptez un bon réflexe : lisez les étiquettes des produits que vous consommez !",
    ],
    imgs:[
        "Images/Alergie1.png",
        "Images/Alergie2.png",
        "Images/Alergie3.png",
    ],

    footerDescs:[
        "Source: ECONOMIE.GOUV.FR",
        "Direction générale de la concurrence, de la consommation et de la répression des fraudes",
        "Lien: https://www.economie.gouv.fr/dgccrf/Publications/Vie-pratique/Fiches-pratiques/Allergene-alimentaire",
    ]
}

export default data;