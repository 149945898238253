import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';
import data from "./data"
import Typography from '../../Atoms/Typography/Typography';


interface IProps{

}

const Allergenes = ({}:IProps):ReactElement =>{
    return (
        <Flex   all={{
                    flexDirection:"column",
                    width:"100%",
                    alignItems:"center"
                }}
        >
            {/* title */}
            <Typography variant='h2' margin='0 0 10px 0 '>
                {data.title}
            </Typography>

            {/* description of title  */}
            {
                data.titleDescs.map(desc=>
                    <Typography variant='h4'>
                        {desc}
                    </Typography>
                )
            }

            {/* some images of allergie */}
            {
                data.imgs.map(img=>
                    <Flex   all={{
                                backgroundColor:"",
                                padding:""
                            }}
                    >
                        
                        <img src={img} alt="" width={"100%"}/>
                    </Flex>

                    
                )
            }

            {/* description of rappel  */}
            <Flex   all={{
                        margin:"40px 0 0 0",
                        flexDirection:"column",
                        alignItems:"center"
                    }}
            >
                {
                    data.footerDescs.map(desc=>
                        <Typography variant='caption1' textAlign='center'>
                            {desc}
                        </Typography>
                    )
                }
            </Flex>
            
        </Flex>
    );
}

export default Allergenes
