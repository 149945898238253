const data = {
    one:{
        title:"WORLD FOOD",
        desc:"WOK - GRILL - BUFFET",
        btnInfo:"RÉSERVER PAR TELEPHONE",
        details:[
            "Cette formule vous permet d’accéder à l’ensemble des entrées, des plats et des dessers compris dans le buffet.",
        ],
        imgSrc:"./Images/homeTop.jpg",
    },
    two:{
        title:"Buffet",
        desc:[
            "Banquettes en moleskine, bonnes bouteilles et belles assiettes.",
            "Buffet est le petit frère d'Au Passage, et sert une cuisine qui fait du bien au corps et à l’esprit.",
            "Des petites assiettes entre 6 et 14€ et de grandes assiettes à partager entre 18 et 24€.",
            "Vins naturels et bio pour tous les goûts, produits frais et de saison, service bien dans ses baskets.",
            "Ouvert du Mardi au Samedi Soir.",
        ],
    },
    three:{
        btnInfo:"DÉCOUVRIR NOTRE CARTE",
        imgSrc:"./Images/fruitDeMer.jpeg"
    },
    four:{
        title:"Infos pratiques",
        info:[
            {
                name:"Cuisine",
                desc:[
                    "Fruits de mer & Poisson, Européenne, Asiatique, Chinoise, Japonaise"
                ]
            },
            {
                name:"FONCTIONNALITÉS",
                desc:[
                    "Plats à emporter, Places assises,",
                    "Réservations, Sert de l'alcool",
                ]
            },
            {
                name:"Moyens de paiement",
                desc:[
                    "Carte Bleue, Visa, Espèces, Titres",
                    "restaurant, Eurocard/Mastercard",
                ]
            }
        ]
    },
    five:{
        title:"Horaires 7J/7",
        info:[
            {
                name:"MIDI",
                desc:[
                    "12H A 14H30",

                ]
            },
            {
                name:"SOIR",
                desc:[
                    "19H À 22H30",

                ]
            },
            // {
            //     name:"Mar - Sam",
            //     desc:[
            //         "19h00 - 23h00",
            //     ]
            // },
            // {
            //     name:"Dimanche",
            //     desc:[
            //         "Fermé",
            //     ]
            // }
        ]
    },
    six:{
        title:"Accès/Contact",
        addr:"8,Rue de la Main d'Or 75011 Paris",
        tel:"0183896382",
    },
    seven:{
        title:"Nous contacter",
        btnInfo:"RÉSERVER",
    }
}

export default data;
