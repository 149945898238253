const data = [
    // {
    //     name:"CARTE & MENUS",
    //     url:"/Menu",
    // },
    
    {
        name:"PHOTOS",
        url:"/Photos",
    },

    {
        name:"Allergènes",
        url:"/Allergenes",
    },
    
    {
        name:"ACCÈS/CONTACT",
        url:"#Contact",
    },
]

export default data;
