import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';
import Typography from '../../Atoms/Typography/Typography';
import data from "./data";
import TitleBlock from '../../Molecules/TitleBlock/TitleBlock';
import { PRIMARY_COLOR } from '../../../EnvVariables/EnvVariables';
import styled from 'styled-components';

interface IProps{}

const AStyle = styled.a`
    :hover{
        text-decoration:underline;
        
    }
`

const Footer = ({}:IProps):ReactElement =>{
    return (
        <Flex   all={{
                    flexDirection:"column",
                    width:"100%",
                    margin:"10px 0 0 0",
                }}
                id="Contact"
        >
            <Flex   all={{
                        flexDirection:"column",
                        backgroundColor:PRIMARY_COLOR,
                        flexGrow:1,
                        // minHeight:"400px",
                        padding:"35px 70px",
                    }}
                    mobile={{
                        padding:"35px 10px"
                    }}
            >
                <TitleBlock text={data.one.title} color="white"
                />

                <Flex   all={{
                            flexDirection:"column",
                            gap:"10px",
                            alignItems:"center"
                        }}
                >
                    <AStyle href="https://www.google.com/maps/dir/48.8655909,2.378983/425+Av.+Boll%C3%A9e,+72000+Le+Mans/@48.4271362,0.1312897,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47e28eb4e304d24f:0xce75bf9b87dc1c87!2m2!1d0.2311432!2d47.999016" 
                            style={{
                                textDecoration:""
                            }}
                    >
                        <Typography variant='caption1'>
                            {data.one.addr}
                        </Typography>
                    </AStyle>
                    
                    <a href={'tel:'+data.one.tel}>
                        <Typography variant='caption1'>
                            {data.one.tel}
                        </Typography>
                    </a>
                    
                    
                    <Flex   all={{width:"100%"}}
                            mobile={{hide:true}}
                            laptop={{hide:true}}
                    >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2669.7604738835157!2d0.2289545156441675!3d47.999015979212714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e28eb4e304d24f%3A0xce75bf9b87dc1c87!2s425%20Av.%20Boll%C3%A9e%2C%2072000%20Le%20Mans!5e0!3m2!1sfr!2sfr!4v1640970379125!5m2!1sfr!2sfr" 
                                width="100%" 
                                height="550" 
                                loading="lazy">        
                        </iframe>
                        
                    </Flex>

                    <Flex   all={{width:"100%"}}
                            desktop={{hide:true}}
                    >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2669.7604738835157!2d0.2289545156441675!3d47.999015979212714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e28eb4e304d24f%3A0xce75bf9b87dc1c87!2s425%20Av.%20Boll%C3%A9e%2C%2072000%20Le%20Mans!5e0!3m2!1sfr!2sfr!4v1640970379125!5m2!1sfr!2sfr" 
                                width="100%" 
                                height="350" 
                                loading="lazy">
                            
                        </iframe>
                    </Flex>
                    
                    
                </Flex>

                
                
            </Flex>
            <Flex all={{
                        justifyContent:"center",
                        padding:"10px"
                    }}
            >
                <Typography variant='caption1' textAlign='center'>
                    © Copyright All Rights Reserved 2022 Edesign 小雯工作室 — <a href="http://www.edesignparis.fr/">edesignparis.fr</a>
                </Typography>
        </Flex>
        </Flex>
    );

}
export default Footer;
