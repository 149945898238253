const data = {
    one:{
        imgSrc:"./Images/menuTop.png",
        title:"Nos plats à volonté"
    },
    two:{
        title:"Grand wok 68 à Le Mans vous souhaite la bienvenue !",
        imgs:[
            {img: "./Images/10.jpg", name:"Entrées"},
            {img: "./Images/11.jpg", name:"Entrées"},

            {img: "./Images/1.jpg", name:"Entrées chaudes"},
            {img: "./Images/2.jpg", name:"Entrées chaudes"},

            {img: "./Images/16.jpg", name:"Suchi & Sashimi"},
            {img: "./Images/17.jpg", name:"California & Maki"},

            {img: "./Images/12.jpg", name:"Plats chauds"},
            {img: "./Images/13.jpg", name:"Plats chauds"},
            {img: "./Images/14.jpg", name:"Plats chauds"},
            {img: "./Images/15.jpg", name:"Plats chauds"},

            {img: "./Images/18.jpg", name:"Fruits de mer"},

            {img: "./Images/23.jpg", name:"Wok"},
            {img: "./Images/24.jpg", name:"Wok"},

            {img: "./Images/19.jpg", name:"Fromages"},
            {img: "./Images/22.jpg", name:"Fruits frais"},

            {img: "./Images/21.jpg", name:"Fruits au sirop"},

            {img: "./Images/3.jpg", name:"Desserts/patisseries"},
            {img: "./Images/4.jpg", name:"Desserts/patisseries"},
            {img: "./Images/5.jpg", name:"Desserts/patisseries"},
            {img: "./Images/6.jpg", name:"Desserts/patisseries"},
            {img: "./Images/7.jpg", name:"Desserts/patisseries"},

            {img: "./Images/8.jpg", name:"Desserts"},
            {img: "./Images/9.jpg", name:"Desserts"},

            {img: "./Images/20.jpg", name:"Bonbons"},
            
            
            
            

        ]
    }
}

export default data;
