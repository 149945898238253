import React from 'react';
import logo from './logo.svg';
import './App.css';
import Flex from './Components/Atoms/Flex/Flex';
import Router from './Router';
import TemplateSite from './Components/Templates/Global1/Global1';
import 'antd/dist/antd.css';

function App() {

  const handleScroll = (e:any)=>{
    console.log("e:", e);
    
  }

  return (
    <div className="App" onScroll={handleScroll}>
      <TemplateSite>
        <Router/>    
      </TemplateSite>
      
    </div>
  );
}

export default App;
