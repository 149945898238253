import { ReactElement,useState,useEffect } from 'react'
import Flex from '../../Atoms/Flex/Flex';

interface IProps{}

const Contact = ({}:IProps):ReactElement =>{
    return (
        <Flex>
            Contact
        </Flex>
    );

}
export default Contact;